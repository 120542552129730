import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { FilterMatchMode, MapItem } from '@capturum/ui/api';
import { Observable } from 'rxjs';
import { ConverterUtil } from '@core/utils/converter-util';
import { map } from 'rxjs/operators';
import { SalesChannel } from '@core/models/sales-channel.model';

@Injectable({
  providedIn: 'root',
})
export class SalesChannelService extends ApiService<SalesChannel> {
  protected endpoint = 'sales-channel';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getItemsAsListOptions(options?: ListOptions): Observable<MapItem[]> {
    return this.list(options).pipe(
      map((response) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  public getItemsAsOptionsWithAuctionStrategyFilter(): Observable<MapItem[]> {
    const options: ListOptions = {
      filters: [
        {
          field: 'auction_strategy',
          value: 1,
          operator: FilterMatchMode.EQUALS,
        },
      ],
    };

    return this.getItemsAsListOptions(options);
  }
}
